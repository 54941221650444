import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXing } from '@fortawesome/free-brands-svg-icons';
import {
  faAward,
  faBars,
  faBuilding,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartLine,
  faClock,
  faDolly,
  faEnvelope,
  faEuroSign,
  faFileContract,
  faGraduationCap,
  faHouse,
  faHouseChimney,
  faLocationDot,
  faMarker,
  faNetworkWired,
  faPaperPlane,
  faParking,
  faPeopleGroup,
  faPhone,
  faStar,
  faSubway,
  faUserTie,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
    imports: [
        CommonModule,
        RouterOutlet,
        TranslateModule,
        StoreModule,
        StoreRouterConnectingModule,
        EffectsModule,
        StoreDevtoolsModule
    ],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'remax';
  constructor(private translate: TranslateService, library: FaIconLibrary) {
    this.translate.setDefaultLang('de');
    this.translate.use('de');
    library.addIcons(
      faXing,
      faInstagram,
      faFacebook,
      faEnvelope,
      faLinkedin,
      faStar,
      faAward,
      faDolly,
      faPeopleGroup,
      faUserTie,
      faHouse,
      faNetworkWired,
      faFileContract,
      faGraduationCap,
      faChartLine,
      faPaperPlane,
      faEuroSign,
      faBuilding,
      faPhone,
      faBars,
      faXmark,
      faCaretUp,
      faCaretLeft,
      faCaretDown,
      faCaretRight,
      faHouseChimney,
      faLocationDot,
      faClock,
      faMarker,
      faCar,
      faSubway,
      faParking
    );
  }
}
const __stripTrailingSlash = (Location).stripTrailingSlash;
Location.stripTrailingSlash = function (url) {
  if (!url.endsWith('/')) {
    url = url + '/';
  }
  const queryString$ = url.match(/([^?]*)?(.*)/);
  if (queryString$[2].length > 0) {
    return /[^/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
  }
  return /[^/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
};
